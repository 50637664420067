// src/components/Footer/Footer.tsx

import React from "react";
import styles from "./Footer.module.css";
import { FaFacebookF } from "react-icons/fa";
import Logo from "../../assets/images/XiangLogo.png";

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.innerContainer}>
        {/* Left Column: Logo and Social Media Links */}
        <div className={styles.leftColumn}>
          <img className={styles.mainLogo} src={Logo} alt="Xiang Logo" />
          <div className={styles.socialLinks}>
            <a
              href="https://www.facebook.com/profile.php?id=100067433583480&ref=page_internal&mt_nav=0"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
              className={styles.socialLink}
            >
              <FaFacebookF className={styles.socialIcon} />
            </a>
          </div>
        </div>

        {/* Middle Column: Horário de funcionamento and Morada */}
        <div className={styles.middleColumn}>
          <div className={styles.infoSection}>
            <h4 className={styles.sectionTitle}>Horário de funcionamento</h4>
            <p>Segunda a Domingo</p>
            <p>12:15 — 14:45 // 19:30 - 22:30</p>
          </div>

          <div className={styles.infoSection}>
            <h4 className={styles.sectionTitle}>Morada</h4>
            <p>
              Azinhaga da Torre do Fato 13
              <br />
              1600-759 Lisboa
              <br />
              Portugal
            </p>
          </div>
        </div>

        {/* Right Column: Contate-nos and Mais Informações */}
        <div className={styles.rightColumn}>
          <div className={styles.infoSection}>
            <h4 className={styles.sectionTitle}>Contate-nos</h4>
            <p>
              T.&nbsp;
              <a href="tel:+351217167684" className={styles.link}>
                +351&nbsp;217&nbsp;167&nbsp;684
              </a>
            </p>
            <p>
              E.&nbsp;
              <a href="mailto:xiang@xiang.pt" className={styles.link}>
                xiang@xiang.pt
              </a>
            </p>
          </div>

          <div className={styles.infoSection}>
            <h4 className={styles.sectionTitle}>Mais Informações</h4>
            <p>
              <a
                href="/pt/privacidade"
                target="_self"
                rel="noopener noreferrer"
                className={styles.link}
              >
                Política de Privacidade
              </a>
            </p>
            <p>
              <a
                href="/pt/termos"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                Termos e Condições
              </a>
            </p>
          </div>
        </div>
      </div>

      {/* Copyright */}
      <div className={styles.copyRight}>
        © 2024 Xiang. Todos os direitos reservados.
      </div>
    </footer>
  );
};

export default Footer;
