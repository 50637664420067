// src/pages/Gallery/Gallery.tsx

import React from "react";
import { useTranslation } from "react-i18next";
import HeroSection from "../../components/HeroSection/HeroSection";
import Footer from "../../components/Footer/Footer";
import GenericGallery from "../../components/GenericGallery/GenericGallery";
import Buffet from "../../assets/images/Buffet.jpg";
import styles from "./Gallery.module.css";
import Dish1 from "../../assets/images/Dish1.jpg";
import Dish2 from "../../assets/images/Dish2.jpg";
import Dish3 from "../../assets/images/Dish3.jpg";
import Dish4 from "../../assets/images/Dish4.jpg";
import Dish5 from "../../assets/images/Dish5.jpg";
import Dish6 from "../../assets/images/Dish6.jpg";
import Dish7 from "../../assets/images/Dish7.jpg";
import Dish8 from "../../assets/images/Dish8.jpg";
import Dish9 from "../../assets/images/Dish9.jpg";
import Dish10 from "../../assets/images/Dish10.jpg";
import Dish11 from "../../assets/images/Dish11.jpg";

const imageList = [
  {
    id: "dish1",
    src: Dish1,
    alt: "Delicious Dish 1",
    title: "Delicious Dish 1",
    description: "A mouth-watering description of dish 1.",
  },
  {
    id: "dish2",
    src: Dish2,
    alt: "Beautiful Venue 1",
    title: "Beautiful Venue 1",
    description: "A stunning description of venue 1.",
  },
  {
    id: "dish3",
    src: Dish3,
    alt: "Beautiful Venue 2",
    title: "Beautiful Venue 2",
    description: "A stunning description of venue 2.",
  },
  {
    id: "dish4",
    src: Dish4,
    alt: "Beautiful Venue 3",
    title: "Beautiful Venue 3",
    description: "A stunning description of venue 3.",
  },
  {
    id: "dish5",
    src: Dish5,
    alt: "Beautiful Venue 4",
    title: "Beautiful Venue 4",
    description: "A stunning description of venue 4.",
  },
  {
    id: "dish6",
    src: Dish6,
    alt: "Beautiful Venue 5",
    title: "Beautiful Venue 5",
    description: "A stunning description of venue 5.",
  },
  {
    id: "dish7",
    src: Dish7,
    alt: "Beautiful Venue 6",
    title: "Beautiful Venue 6",
    description: "A stunning description of venue 6.",
  },
  {
    id: "dish8",
    src: Dish8,
    alt: "Beautiful Venue 7",
    title: "Beautiful Venue 7",
    description: "A stunning description of venue 7.",
  },
  {
    id: "dish9",
    src: Dish9,
    alt: "Beautiful Venue 8",
    title: "Beautiful Venue 8",
    description: "A stunning description of venue 8.",
  },
  {
    id: "dish10",
    src: Dish10,
    alt: "Beautiful Venue 8",
    title: "Beautiful Venue 8",
    description: "A stunning description of venue 8.",
  },
  {
    id: "dish11",
    src: Dish11,
    alt: "Beautiful Venue 8",
    title: "Beautiful Venue 8",
    description: "A stunning description of venue 8.",
  },
];

const Gallery: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeroSection
        title={t("gallery.title")}
        subtitle={t("gallery.subtitle")}
        backgroundImage={Buffet}
      />
      <div className={styles.gallerySection}>
        <GenericGallery images={imageList} />
      </div>
      <Footer />
    </>
  );
};

export default Gallery;
