// src/pages/Contact/ContactPage.tsx

import React from "react";
import { useTranslation } from "react-i18next";
import HeroSection from "../../components/HeroSection/HeroSection";
import Footer from "../../components/Footer/Footer";
import RestaurantFront from "../../assets/images/RestaurantFront.jpg";
import styles from "./ContactPage.module.css";
import InspirationSection from "../../components/InspirationSection/InspirationSection";

const ContactPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeroSection
        title={t("contact_page.title")}
        subtitle={t("contact_page.subtitle")}
        backgroundImage={RestaurantFront}
        variant="default"
      />
      <div className={styles.contactSection}>
        <InspirationSection
          imageSrc={RestaurantFront}
          imageAlt="Restaurant Front"
          title="CONTACTOS"
          subtitle="FALE CONNOSCO"
          phone="+351 217 167 684"
          email="xiang@xiang.pt"
          buttons={[
            {
              text: "Direções Google Maps",
              link: "https://www.google.com/maps/place/Restaurante+Xiang+Telheiras/@38.7618063,-9.1803238,17z/data=!3m1!4b1!4m6!3m5!1s0xd1932dab22ebac3:0x8128b4ccaa86af34!8m2!3d38.7618063!4d-9.1777489!16s%2Fg%2F1tfznly1?hl=pt-PT&entry=ttu&g_ep=EgoyMDI0MTAyOS4wIKXMDSoASAFQAw%3D%3D",
              newTab: true, // Opens in a new tab
            },
          ]}
        />
        <InspirationSection
          embedMap="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3111.0827385968264!2d-9.18032382348617!3d38.761806271753365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1932dab22ebac3%3A0x8128b4ccaa86af34!2sRestaurante%20Xiang%20Telheiras!5e0!3m2!1spt-PT!2spt!4v1730837333654!5m2!1spt-PT!2spt"
          imageAlt="Google Map"
          title="Morada"
          subtitle="Azinhaga da Torre do Fato 13, 1600-759 Lisboa"
          buttons={[
            {
              text: "Direções Google Maps",
              link: "https://www.google.com/maps/place/Restaurante+Xiang+Telheiras/@38.7618063,-9.1803238,17z/data=!3m1!4b1!4m6!3m5!1s0xd1932dab22ebac3:0x8128b4ccaa86af34!8m2!3d38.7618063!4d-9.1777489!16s%2Fg%2F1tfznly1?hl=pt-PT&entry=ttu&g_ep=EgoyMDI0MTAyOS4wIKXMDSoASAFQAw%3D%3D",
              newTab: true, // Opens in a new tab
            },
          ]}
          reverse
        />
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
