// App.tsx
import React, { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "./i18n";
import CookieConsentBanner from "./components/Cookie/CookieConsentBanner";
import LanguageWrapper from "./components/LanguageWrapper/LanguageWrapper";
import LanguageRoutes from "./components/LanguageRoutes/LanguageRoutes";
import Layout from "./components/Layout/Layout";
import {
  CookieConsentProvider,
  // useCookieConsent,
} from "./components/Cookie/CookieConsentContext";

// // Extend the Window interface for dataLayer
// declare global {
//   interface Window {
//     dataLayer: any[];
//   }
// }

// const LoadAnalyticsScripts = () => {
//   const { consent } = useCookieConsent();

//   useEffect(() => {
//     if (consent.analytics) {
//       // Load your analytics script, e.g., Google Analytics
//       const script = document.createElement("script");
//       script.src = "https://www.googletagmanager.com/gtag/js?id=GTM-5JNZG8TV";
//       script.async = true;
//       document.head.appendChild(script);

//       script.onload = () => {
//         window.dataLayer = window.dataLayer || [];
//         function gtag(...args: any) {
//           window.dataLayer.push(args);
//         }
//         gtag("js", new Date());
//         gtag("config", "GTM-5JNZG8TV");
//       };
//     }
//     // Add marketing script if consent.marketing is true
//   }, [consent]);

//   return null;
// };

const App: React.FC = () => {
  return (
    <CookieConsentProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <Layout>
          <Routes>
            <Route path="/" element={<Navigate to="/pt" replace />} />
            <Route path="/:lng/*" element={<LanguageWrapper />}>
              <Route path="*" element={<LanguageRoutes />} />
            </Route>
          </Routes>
          <CookieConsentBanner />
          {/* <LoadAnalyticsScripts /> */}
        </Layout>
      </Suspense>
    </CookieConsentProvider>
  );
};

export default App;
