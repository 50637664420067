// src/pages/HomePage.tsx

import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import HeroSection from "../../components/HeroSection/HeroSection";
import InspirationSection from "../../components/InspirationSection/InspirationSection";
import FlatSection from "../../components/FlatSection/FlatSection";
import TripleCardSection from "../../components/TripleCardSection/TripleCardSection";
import Footer from "../../components/Footer/Footer";

// Importing Images
import Buffet from "../../assets/images/Buffet.jpg";
import Dish1 from "../../assets/images/Dish1.jpg";
import Dish2 from "../../assets/images/Dish2.jpg";
import Dish10 from "../../assets/images/Dish10.jpg";
import Dish11 from "../../assets/images/Dish11.jpg";
import RestaurantFront from "../../assets/images/RestaurantFront.jpg";

const HomePage: React.FC = () => {
  const { t } = useTranslation();
  const { lng } = useParams<{ lng: string }>();
  const currentLanguage = lng || "pt";

  // Define the cards data with imported images
  const cards = [
    {
      imageSrc: Dish1,
      alt: "Memories from Brazil Menu: Dish.",
      title: "O Menu",
      description:
        "O nosso menu é inspirado pela nossa própria jornada para um aspecto diferente do nosso legado alimentar. Junte-se a nós enquanto celebramos o que torna cada filosofia gastronómica única.",
      buttonText: "Explorar",
      buttonLink: "/pt/contacto",
    },
    {
      imageSrc: Dish2,
      alt: "Memories from Brazil Menu: Dish",
      title: "Reconhecimento",
      description:
        "A nossa equipa partilha uma paixão por elevar a cozinha portuguesa ao mais alto nível, ao mesmo tempo que cria experiências memoráveis para todos os nossos convidados.",
      buttonText: "Explorar",
      buttonLink: "/pt/contacto",
    },
    {
      imageSrc: RestaurantFront,
      alt: "Ocean restaurant entrance.",
      title: "Encontre-nos",
      description:
        "Junte-se a nós numa viagem através do oceano, explore os sabores e aromas da nossa história.",
      buttonText: "Explorar",
      buttonLink: "/pt/contacto",
    },
  ];

  return (
    <>
      <HeroSection
        title={t("home.hero_title")}
        subtitle={t("home.hero_subtitle")}
        ctaText={t("home.hero_cta_text")}
        ctaLink={`/${currentLanguage}${t("home.hero_cta_link")}`}
        backgroundImage={Dish2}
        variant="home"
      />

      {/* First Inspiration Section with Multiple Buttons */}
      <InspirationSection
        imageSrc={Dish10}
        imageAlt="Ocean restaurant team."
        title="Inspire-se para descobrir"
        subtitle="A criação do nosso menu"
        description="Cada menu é uma jornada de descoberta. Ao mergulhar nas raízes da cultura gastronômica chinesa, levamos nossos convidados a explorar os ingredientes, técnicas e tradições locais. Uma filosofia que celebra a conexão entre o passado e o presente."
        buttons={[
          {
            text: "Explorar",
            link: "/pt/contacto",
            // newTab is optional and defaults to false if not provided
          },
        ]}
      />

      {/* Second Inspiration Section with Reversed Layout and Single Button */}
      <InspirationSection
        imageSrc={Dish11}
        imageAlt="Trip to Belem: Cocoa beans."
        title="Uma viagem connosco"
        subtitle="Conheça a nossa equipa"
        description="Com paixão e dedicação, nossa equipe compartilha a alegria de redescobrir o passado e reimaginar o encanto que inspira. Buscamos nas nossas memórias a simplicidade e a pureza que o tempo pode ter deixado para trás."
        buttons={[
          {
            text: "Explorar",
            link: "/pt/contacto",
            newTab: false,
          },
          // Optionally, add more buttons here
        ]}
        reverse
      />

      <FlatSection
        alt="Preparation"
        title="Galeria"
        description="Quando olhamos para a China, vemos um mundo vibrante de cores e tradições. Seguindo as suas raízes, abrimo-nos a um universo de sentidos e sabores."
        buttonText="Explorar"
        buttonLink="/pt/galeria"
        imageSrc={Buffet} // Consider using a different image for variety
        height="h-96" /* Optional: customize as needed */
        margin="my-16" /* Optional: customize as needed */
      />

      {/* Integrate TripleCardSection */}
      <TripleCardSection cards={cards} />

      <Footer />
    </>
  );
};

export default HomePage;
