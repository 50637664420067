// Nav.tsx
import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/images/XiangLogo.png";
import { FaBars } from "react-icons/fa";
import styles from "./Nav.module.css";

interface NavProps {
  onHamburgerClick: () => void;
}

const Nav: React.FC<NavProps> = ({ onHamburgerClick }) => {
  const { lng } = useParams<{ lng: string }>();
  const language = lng || "pt";
  const { t } = useTranslation();

  // State to handle background color change on scroll
  const [navBackground, setNavBackground] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const showBackground = window.scrollY > 50;
      setNavBackground(showBackground);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`${styles.header} ${
        navBackground ? styles.navBackground : styles.headerTransparent
      }`}
    >
      <div className={styles.container}>
        {/* Left Column: Hamburger Menu */}
        <div className={styles.leftColumn}>
          <button
            className={styles.hamburgerButton}
            aria-label="Toggle Menu"
            onClick={onHamburgerClick} // Attach the click handler
          >
            <FaBars />
          </button>
        </div>

        {/* Middle Column: Logo */}
        <div className={styles.middleColumn}>
          <NavLink
            to={`/${language}${t("routes.home")}`}
            className={styles.logoLink}
          >
            <img src={Logo} alt="Logo" className={styles.logoImage} />
          </NavLink>
        </div>

        {/* Right Column: CTA Button */}
        <div className={styles.rightColumn}>
          <NavLink to={`/${language}${t("routes.reservar")}`}>
            <button className={styles.ctaButton}>{t("nav.reservar")}</button>
          </NavLink>
        </div>
      </div>
    </header>
  );
};

export default Nav;
